
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class LayoutModal extends Vue {
    @Prop() open!: boolean;
    @Prop({ default: false }) allowEscape!: boolean;

    @Prop({ default: "inside" }) xPosition!: "inside" | "outside";

    @Prop({ default: 400 }) width!: number | "auto";

    @Prop({ default: 32 }) padding!: number;

    @Ref() focuscontainer!: HTMLDivElement;

    handleEscape() {
        if (this.allowEscape) {
            this.$emit("close");
        }
    }

    focus() {
        this.$nextTick(() => {
            this.$nextTick(() => {
                if (this.focuscontainer) {
                    this.focuscontainer.focus();
                }
            });
        });
    }

    get isTouchDevice() {
        //@ts-ignore
        return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }
}
